/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Typography, Input, Form, Button, Row, message,
} from 'antd'
import { useState } from 'react'
import { Formik, Field } from 'formik'
import { waitForTransaction } from '@wagmi/core'
import { useNetwork } from 'wagmi'

import {
  useBlockExplorer, useIdentity,
} from '../../shared/hooks'
import { FieldWithFetch } from '../../shared/components'
import { addUserToWhitelistFormScheme } from '../../shared/validation-schemes'
import { WhitelistForm } from '../../shared/types'
import { handleError } from '../../shared/lib'

const { Title, Text } = Typography

const ManageWhitelist = () => {
  const [isUserRemoving, setUserRemoving] = useState(false)

  const { chain } = useNetwork()

  const {
    getWhitelistId,
    addToWhitelist,
    removeFromWhitelist,
  } = useIdentity()

  const { showExplorerMessage } = useBlockExplorer()

  const fetchWhitelistId = async (address: string) => {
    const id = await getWhitelistId(address)

    if (id) {
      return id
    }

    return null
  }

  const handleFormSubmit = async ({ address, whitelistId }: WhitelistForm, resetForm: () => void) => {
    try {
      const { hash } = await addToWhitelist(address, whitelistId)

      showExplorerMessage(hash)

      await waitForTransaction({ chainId: chain?.id, hash })

      message.success('User was added to whitelist')
    } catch (error) {
      handleError(error)
    } finally {
      resetForm()
    }
  }

  const handleRemoveClick = async ({ address }: WhitelistForm, resetForm: () => void) => {
    setUserRemoving(true)

    try {
      const { hash } = await removeFromWhitelist(address)

      showExplorerMessage(hash)

      await waitForTransaction({ chainId: chain?.id, hash })

      message.success('User was removed')
    } catch (error) {
      handleError(error)
    } finally {
      resetForm()
    }

    setUserRemoving(false)
  }

  return (
    <>
      <Title level={2} style={{ marginBottom: 10 }}>Manage Whitelist</Title>
      <Text style={{ display: 'block', marginBottom: 25 }} type="secondary">
        You can add or remove wallets from the whitelist.
        Only whitelisted people are considered as Investors and are able to send or receive token on Blockchain.
      </Text>
      <Formik
        initialValues={{
          address: '',
          whitelistId: '',
          isFetchedWhitelistId: false,
        }}
        validationSchema={addUserToWhitelistFormScheme}
        onSubmit={(values, { resetForm }) => handleFormSubmit(values, resetForm)}
      >
        {({
          handleSubmit, errors, touched, values, isSubmitting, handleReset,
        }) => (
          <Form layout="vertical" style={{ width: '100%' }} onFinish={handleSubmit}>
            <Form.Item
              help={(errors.address && touched.address) && errors.address}
              label="Wallet address (Ethereum)"
              validateStatus={(errors.address && touched.address) ? 'error' : 'success'}
            >
              <Field
                allowClear
                as={Input}
                name="address"
                placeholder="0x00…0000"
              />
            </Form.Item>

            <FieldWithFetch
              as={Input}
              fetchAction={fetchWhitelistId}
              isFetchedFieldName="isFetchedWhitelistId"
              label="Wallet alias"
              name="whitelistId"
              placeholder="John Smith"
            />
            <Form.Item>
              <Row justify="space-between" style={{ width: '100%', marginTop: 10 }}>
                <Button
                  disabled={values.isFetchedWhitelistId}
                  htmlType="submit"
                  loading={isSubmitting}
                  style={{ width: '48%' }}
                  type="primary"
                >
                  Add
                </Button>
                <Button
                  danger
                  disabled={!values.isFetchedWhitelistId}
                  loading={isUserRemoving}
                  style={{ width: '48%' }}
                  type="primary"
                  onClick={() => handleRemoveClick(values, handleReset)}
                >
                  Remove
                </Button>
              </Row>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ManageWhitelist

