import { useContext } from 'react'
import {
  useNetwork, useSigner, useContract,
} from 'wagmi'

import { StoreContext } from '../../../app/providers/store'
import { XTokenProxy } from '../../abi'

const useProxyContract = () => {
  const { currentTokenPair } = useContext(StoreContext)

  const { chain } = useNetwork()

  const { data: signer } = useSigner({ chainId: chain?.id })

  const proxyContract = useContract({
    address: currentTokenPair.proxy,
    abi: XTokenProxy.abi,
    signerOrProvider: signer,
  })

  return { proxyContract }
}

export default useProxyContract
