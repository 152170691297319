import {
  Input, Form, Space, Button, message, InputNumber, DatePicker, Checkbox,
} from 'antd'
import { Formik, Field } from 'formik'
import dayjs, { Dayjs } from 'dayjs'
import { waitForTransaction } from '@wagmi/core'
import { useNetwork } from 'wagmi'

import { useBlockExplorer, useIdentity, useToken } from '../../shared/hooks'
import { FieldWithFetch } from '../../shared/components'
import { mintTokensScheme } from '../../shared/validation-schemes'
import { MintTokenForm } from '../../shared/types'
import { handleError } from '../../shared/lib'

const MintTokens = () => {
  const { chain } = useNetwork()

  const { mintTokens } = useToken()
  const { getWhitelistId } = useIdentity()

  const { showExplorerMessage } = useBlockExplorer()

  const fetchWhitelistId = async (walletAddress: string) => {
    const id = await getWhitelistId(walletAddress)

    if (id) {
      return id
    }

    return null
  }

  const handleFormSubmit = async ({
    address, whitelistId, amount, releaseDate, withReleaseDate,
  }: MintTokenForm, resetForm: () => void) => {
    try {
      const date = withReleaseDate ? releaseDate : undefined

      const { hash } = await mintTokens(address, whitelistId, amount, date)

      showExplorerMessage(hash)

      await waitForTransaction({ chainId: chain?.id, hash })

      message.success('Tokens have been minted')
    } catch (error) {
      handleError(error)
    } finally {
      resetForm()
    }
  }

  const disabledDate = (current: Dayjs) => current && current <= dayjs()
    .endOf('day')

  return (
    <Formik
      initialValues={{
        address: '',
        whitelistId: '',
        amount: 0.001,
        releaseDate: undefined,
        isFetchedWhitelistId: false,
        withReleaseDate: true,
      }}
      validationSchema={mintTokensScheme}
      onSubmit={(values, { resetForm }) => handleFormSubmit(values, resetForm)}
    >
      {({
        handleSubmit, errors, touched, values, isSubmitting, setFieldValue,
      }) => (
        <Form layout="vertical" style={{ width: '100%' }} onFinish={handleSubmit}>
          <Form.Item
            help={(errors.address && touched.address) && errors.address}
            label="Wallet address (Ethereum)"
            validateStatus={(errors.address && touched.address) ? 'error' : 'success'}
          >
            <Field
              allowClear
              as={Input}
              name="address"
              placeholder="0x00…0000"
            />
          </Form.Item>
          <FieldWithFetch
            as={Input}
            fetchAction={fetchWhitelistId}
            isFetchedFieldName="isFetchedWhitelistId"
            label="Wallet alias"
            name="whitelistId"
            placeholder="John Smith"
          />
          <Form.Item
            help={(errors.amount && touched.amount) && errors.amount}
            label="Amount of tokens to mint and send"
            validateStatus={(errors.amount && touched.amount) ? 'error' : 'success'}
          >
            <InputNumber
              min={0.001}
              name="amount"
              placeholder="Amount"
              step={0.001}
              value={values.amount}
              onChange={(value) => setFieldValue('amount', value)}
            />
          </Form.Item>
          <Form.Item
            help={(errors.releaseDate && touched.releaseDate) && errors.releaseDate}
            label="Release date"
            validateStatus={(errors.releaseDate && touched.releaseDate) ? 'error' : 'success'}
          >
            <Space size={20}>
              <DatePicker
                allowClear
                disabled={!values.withReleaseDate}
                disabledDate={(current) => disabledDate(current)}
                placeholder="Select release date"
                showNow={false}
                showTime={{ format: 'HH:mm' }}
                value={values.releaseDate ? dayjs(values.releaseDate) : undefined}
                onChange={(value) => setFieldValue('releaseDate', value)}
              />
              <Checkbox
                checked={values.withReleaseDate}
                onChange={({ target }) => setFieldValue('withReleaseDate', target.checked)}
              >
                With Release Date
              </Checkbox>
            </Space>
          </Form.Item>
          <Form.Item>
            <Button
              block
              htmlType="submit"
              loading={isSubmitting}
              style={{ marginTop: 10 }}
              type="primary"
            >
              Mint tokens
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}

export default MintTokens

