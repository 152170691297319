/* eslint-disable no-underscore-dangle */
import { useContext } from 'react'
import {
  useContract, useNetwork, useSigner,
} from 'wagmi'

import { XTokenLite } from '../../abi'
import { StoreContext } from '../../../app/providers/store'

const useTokenContract = () => {
  const { currentTokenPair } = useContext(StoreContext)

  const { chain } = useNetwork()
  const { data: signer } = useSigner({ chainId: chain?.id })

  const tokenContract = useContract({
    address: currentTokenPair.contract,
    abi: XTokenLite.abi,
    signerOrProvider: signer,
  })

  const checkPaused = async () => {
    const state = await tokenContract?._paused()

    return state
  }

  const switchPause = async (state: 'pause' | 'unpause') => {
    if (state === 'pause') {
      return await tokenContract?.pause()
    }

    return await tokenContract?.unpause()
  }

  return { tokenContract, switchPause, checkPaused }
}

export default useTokenContract
