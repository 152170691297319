import { Form, InputProps } from 'antd'
import {
  Field, FieldConfig, useFormikContext,
} from 'formik'
import { useCallback, useEffect, useState } from 'react'

interface Props extends FieldConfig<InputProps> {
  fetchAction: (walletAddress: string) => Promise<string | null>
  label: string
  placeholder: string
  name: PossibleFormFieldsKey
  isFetchedFieldName: PossibleFormFieldsKey
  options?: { value: string, label: string }[]
  onSelect?: (value: string) => void
  withDisableAfterFetch?: boolean
}

const FieldWithFetch = ({
  fetchAction, isFetchedFieldName, label, name, options, placeholder, withDisableAfterFetch, ...props
}: Props) => {
  const {
    values, setFieldValue, errors, touched,
  } = useFormikContext<PossibleFormFields>()

  const [isValueFetching, setValueFetching] = useState(false)

  const fetchValue = useCallback(async (address: string) => {
    setValueFetching(true)

    try {
      const value = await fetchAction(address)

      if (value) {
        setFieldValue(name, value)
        setFieldValue(isFetchedFieldName, true)
      }
    } catch (error) {
      setFieldValue(isFetchedFieldName, false)
    } finally {
      setValueFetching(false)
    }
  }, [fetchAction, isFetchedFieldName, name, setFieldValue])

  useEffect(() => {
    if (errors.address && touched.address && values.address) {
      setFieldValue(name, null)
      setFieldValue(isFetchedFieldName, false)
    } else if (!errors.address && values.address) {
      fetchValue(values.address)
    }
  }, [errors.address, values.address, isFetchedFieldName, touched.address, setFieldValue, name, fetchValue])

  useEffect(() => {
    if (values[name] && values?.address?.length === 0) {
      setFieldValue(name, null)

      setFieldValue(isFetchedFieldName, false)
    }
  }, [values, isFetchedFieldName, name, setFieldValue])

  const getValidateStatus = () => {
    if (isValueFetching) {
      return 'validating'
    }

    if (errors[name] && touched[name]) {
      return 'error'
    }

    return ''
  }

  return (
    <Form.Item
      hasFeedback
      help={errors[name] && touched[name] && errors[name]}
      label={label}
      validateStatus={getValidateStatus()}
    >
      <Field
        disabled={
          (withDisableAfterFetch && values[isFetchedFieldName])
          || (errors.address) || values.address.length === 0
        }
        name={name}
        options={options}
        placeholder={placeholder}
        {...props}
      />
    </Form.Item>
  )
}

type PossibleFormFields = {
  address: string
  prefix?: string
  whitelistId?: string
  isFetchedPrefix?: string
  isFetchedUserRole?: string
  isFetchedWhitelistId?: string
  role?: string
}

type PossibleFormFieldsKey = keyof PossibleFormFields

FieldWithFetch.defaultProps = {
  options: null,
  onSelect: null,
  withDisableAfterFetch: true,
}

export default FieldWithFetch
