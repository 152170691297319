import { Button } from 'antd'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;

    > .anticon, .ant-btn-loading-icon {
        position: absolute;
        top: 8px;
        left: 37px;
    }
`
