import styled from 'styled-components'

export const StyledBackground = styled.div`
    width: 100%;
    padding: 30px;

    border-radius: 12px;
    
    background-color: #FFFFFF;    
    box-shadow: 0px 0px 4px -1px rgba(202, 202, 202, 0.8);
`
