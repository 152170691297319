import { Outlet } from 'react-router-dom'

import { Header } from '../../widgets'

import { StyledLayout, StyledContent } from './styles'

const Layout = () => (
  <StyledLayout>
    <Header />
    <StyledContent>
      <Outlet />
    </StyledContent>
  </StyledLayout>
)

export default Layout
