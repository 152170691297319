import {
  createClient,
  configureChains,
} from 'wagmi'
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask'
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect'
import { CoinbaseWalletConnector } from '@wagmi/core/connectors/coinbaseWallet'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { mainnet, goerli } from 'wagmi/chains'

export const getWagmiClient = () => {
  const { chains, provider, webSocketProvider } = configureChains(
    [mainnet, goerli],
    [alchemyProvider({ apiKey: `${process.env.REACT_APP_ALCHEMY_ID}` })],
  )

  const connectors = [
    new MetaMaskConnector({
      chains,
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: { appName: 'TIRC Management Panel' },
    }),
  ]

  const client = createClient({
    provider,
    autoConnect: true,
    connectors,
    webSocketProvider,
  })

  return client
}

