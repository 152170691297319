import {
  Avatar, Space, Typography, Grid,
} from 'antd'

import tinagaLogo from '../../assets/tinagaLogo.png'
import { WalletInfo } from '../../features'

import { StyledHeader } from './styles'

const { Title } = Typography
const { useBreakpoint } = Grid
const Header = () => {
  const { xs } = useBreakpoint()

  return (
    <StyledHeader>
      <Space align="center">
        <Avatar size={32} src={tinagaLogo} />
        {!xs && (
          <Title
            level={4}
            style={{ margin: 0, color: 'white', whiteSpace: 'nowrap' }}
          >
            TIRC Management Panel

          </Title>
        )}
      </Space>
      <WalletInfo />
    </StyledHeader>
  )
}

export default Header
