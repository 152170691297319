import { waitForTransaction } from '@wagmi/core'
import { message, Result, Button } from 'antd'
import { useCallback, useContext, useState } from 'react'
import { useNetwork } from 'wagmi'

import { StoreContext } from '../../app/providers/store'
import { useTokenContract, useBlockExplorer } from '../../shared/hooks'
import { handleError } from '../../shared/lib'

const PausedContractWarning = () => {
  const { setTokenContractPaused } = useContext(StoreContext)

  const [isTokenContractUnpausing, setTokenContractUnpausing] = useState(false)

  const { chain } = useNetwork()

  const { switchPause } = useTokenContract()
  const { showExplorerMessage } = useBlockExplorer()

  const handleUnpauseClick = useCallback(async () => {
    setTokenContractUnpausing(true)

    try {
      const { hash } = await switchPause('unpause')

      showExplorerMessage(hash)

      await waitForTransaction({ chainId: chain?.id, hash })

      message.success('Token was switched to unpaused')

      setTokenContractPaused(false)
    } catch (error) {
      handleError(error)
    }

    setTokenContractUnpausing(false)
  }, [chain?.id, setTokenContractPaused, showExplorerMessage, switchPause])

  return (
    <Result
      extra={(
        <Button
          loading={isTokenContractUnpausing}
          type="primary"
          onClick={handleUnpauseClick}
        >
          Unpause
        </Button>
      )}
      status="warning"
      title="Token contract is paused."
    />
  )
}

export default PausedContractWarning
