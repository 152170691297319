/* eslint-disable react-hooks/exhaustive-deps */
import {
  Row, Col, Grid, Spin, Typography,
} from 'antd'
import {
  useCallback, useContext, useEffect, useState,
} from 'react'

import { StoreContext } from '../../app/providers/store'
import {
  ManageUsers, ManageWhitelist, MintTokens, PausedContractWarning,
} from '../../features'
import { useTokenContract } from '../../shared/hooks'

import { StyledBackground } from './styles'

const { useBreakpoint } = Grid
const { Title, Text } = Typography

const Main = () => {
  const {
    walletInfo,
    isTokenContractPaused,
    setTokenContractPaused,
  } = useContext(StoreContext)
  const { identity } = walletInfo || {}

  const [isTokenContractStateFetching, setTokenContractStateFetching] = useState(false)

  const screens = useBreakpoint()

  const { checkPaused } = useTokenContract()

  const isFullAccessUser = identity?.role && ['admin', 'owner'].includes(identity?.role)

  const fetchTokenContractState = useCallback(async () => {
    setTokenContractStateFetching(true)

    const isPaused = await checkPaused()

    setTokenContractStateFetching(false)

    setTokenContractPaused(isPaused)
  }, [checkPaused, setTokenContractPaused, setTokenContractStateFetching])

  useEffect(() => {
    if (walletInfo !== null) { fetchTokenContractState() }
  }, [walletInfo])

  if (screens.xs) {
    return (
      <Col>
        <Col span={24} style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
          <StyledBackground>
            <ManageWhitelist />
          </StyledBackground>
          {isFullAccessUser && (
            <StyledBackground>
              <ManageUsers />
            </StyledBackground>
          )}
        </Col>
        {isFullAccessUser && (
          <Col span={24} style={{ marginTop: 20 }}>
            <Spin spinning={isTokenContractStateFetching}>
              <StyledBackground>
                <Title level={2} style={{ marginBottom: 10 }}>Mint Tokens</Title>
                <Text style={{ display: 'block', marginBottom: 25 }} type="secondary">
                  You can mint and send TIRC tokens to anyone. If the wallet is not whitelisted,
                  it will be whitelisted automatically. Release date (optional) is a point in the
                  future when tokens will be unlocked and user will be able to use them.
                </Text>
                {isTokenContractPaused
                  ? <PausedContractWarning />
                  : <MintTokens />}
              </StyledBackground>
            </Spin>
          </Col>
        )}
      </Col>
    )
  }

  return (
    <Row gutter={30} justify="space-between">
      <Col span={12} style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <StyledBackground>
          <ManageWhitelist />
        </StyledBackground>
        {isFullAccessUser && (
          <StyledBackground>
            <ManageUsers />
          </StyledBackground>
        )}
      </Col>
      {isFullAccessUser && (
        <Col span={12}>
          <Spin spinning={isTokenContractStateFetching}>
            <StyledBackground>
              <Title level={2} style={{ marginBottom: 10 }}>Mint Tokens</Title>
              <Text style={{ display: 'block', marginBottom: 25 }} type="secondary">
                You can mint and send TIRC tokens to anyone. If the wallet is not whitelisted,
                it will be whitelisted automatically. Release date (optional) is a point in the
                future when tokens will be unlocked and user will be able to use them.
              </Text>
              {isTokenContractPaused
                ? <PausedContractWarning />
                : <MintTokens />}
            </StyledBackground>
          </Spin>
        </Col>
      )}
    </Row>
  )
}

export default Main

