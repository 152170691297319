
import { ethers } from 'ethers'
import { useContext } from 'react'
import dayjs from 'dayjs'

import { useTokenContract } from '..'
import { StoreContext } from '../../../app/providers/store'

const useToken = () => {
  const { walletInfo } = useContext(StoreContext)

  const { tokenContract } = useTokenContract()

  const getToken = async () => {
    const symbol: string = await tokenContract?.symbol()
    const decimals = await tokenContract?.decimals()

    return { symbol, decimals }
  }

  const mintTokens = async (address: string, whitelistId: string, amount: number, releaseDate: string | undefined) => {
    const { decimals } = walletInfo?.token || {}

    const convertedWhitelistId = ethers.utils.formatBytes32String(whitelistId)
    const convertedAmount = ethers.utils.parseUnits(`${amount}`, decimals)

    if (releaseDate) {
      const timestamp = dayjs(releaseDate)
        .unix()

      return await tokenContract?.issue(
        address,
        convertedWhitelistId,
        convertedAmount,
        timestamp,
        true,
      )
    }

    return await tokenContract?.issue(address, convertedWhitelistId, convertedAmount, 0, true)
  }

  return { getToken, mintTokens }
}

export default useToken

