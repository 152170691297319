import { Typography, Space, Statistic } from 'antd'
import { useContext } from 'react'

import { StoreContext } from '../../app/providers/store'

import { StyledBackground } from './styles'

const { Title } = Typography

const WhitelistInfo = () => {
  const { walletInfo } = useContext(StoreContext)
  const { identity, balance, token } = walletInfo || {}

  return (
    <StyledBackground>
      <Title level={2}>Whitelist Info</Title>
      <Space size={40}>
        <Statistic title="Wallet alias" value={identity?.whitelistId} />
        <Statistic title="Balance" value={`${balance} ${token?.symbol}`} />
      </Space>
    </StyledBackground>
  )
}

export default WhitelistInfo
