import { TokenPair } from '../types'

export const mainnetTokenPair: TokenPair = {
  name: 'TinagaToken',
  contract: '0xe19CEB39812eb841c0a217dcca9347f4373ddd45',
  proxy: '0xe69e3206128fb61b050bbb1DA9dF2aCb0c5C3F6A',
}

export const goerliTokenPair: TokenPair = {
  name: 'TinagaToken',
  contract: '0x64D7f7e7B9ddB6f9D6BeCc70cb1726A98ea76E21',
  proxy: '0xD8cd48382F6B16a6600D30e4dc54D364D4621400',
}

export const tokenPairsById: { [key: number]: TokenPair } = {
  5: goerliTokenPair,
  1: mainnetTokenPair,
}
