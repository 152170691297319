/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Typography, Input, Form, Button, Row, message, Select,
} from 'antd'
import { useContext, useState } from 'react'
import { Formik, Field } from 'formik'
import { waitForTransaction } from '@wagmi/core'
import { useNetwork } from 'wagmi'

import {
  useBlockExplorer, useIdentity,
} from '../../shared/hooks'
import { FieldWithFetch } from '../../shared/components'
import { addUserFormScheme } from '../../shared/validation-schemes'
import { UserForm } from '../../shared/types'
import { StoreContext } from '../../app/providers/store'
import { handleError } from '../../shared/lib'

const { Title, Text } = Typography
const { Option } = Select

const ManageUsers = () => {
  const { walletInfo } = useContext(StoreContext)
  const { identity } = walletInfo || {}

  const [isUserRemoving, setUserRemoving] = useState(false)

  const { chain } = useNetwork()

  const {
    getAgentPrefix,
    addAgent,
    removeAgent,
    checkAgent,
    addAdmin,
    removeAdmin,
    checkAdmin,
    checkOwner,
  } = useIdentity()

  const { showExplorerMessage } = useBlockExplorer()

  const fetchUserRole = async (address: string) => {
    const isOwner = await checkOwner(address)

    if (isOwner) {
      return 'owner'
    }

    const isAgent = await checkAgent(address)

    if (isAgent) {
      return 'agent'
    }

    const isAdmin = await checkAdmin(address)

    if (isAdmin) {
      return 'admin'
    }

    return null
  }

  const fetchAgentPrefix = async (address: string) => {
    const isAgent = await checkAgent(address)

    if (isAgent) {
      const prefix = await getAgentPrefix(address)

      return prefix
    }

    return null
  }

  const addUserByRole: AddUserByRole = {
    agent: ({ address, prefix }) => addAgent(address, prefix),
    admin: ({ address }) => addAdmin(address),
  }

  const removeUserByRole: RemoveUserByRole = {
    agent: ({ address }) => removeAgent(address),
    admin: ({ address }) => removeAdmin(address),
  }

  const handleFormSubmit = async (values: UserForm, resetForm: () => void) => {
    try {
      const { hash } = await addUserByRole[values.role as AddUserByRoleKey](values)

      showExplorerMessage(hash)

      await waitForTransaction({ chainId: chain?.id, hash })

      message.success('User was added')
    } catch (error) {
      handleError(error)
    } finally {
      resetForm()
    }
  }

  const handleRemoveUserClick = async (values: UserForm, resetForm: () => void) => {
    setUserRemoving(true)

    try {
      const { hash } = await removeUserByRole[values.role as RemoveUserByRoleKey](values)

      showExplorerMessage(hash)

      await waitForTransaction({ chainId: chain?.id, hash })

      message.success('User was removed')
    } catch (error) {
      handleError(error)
    } finally {
      resetForm()
    }

    setUserRemoving(false)
  }

  const renderSecondaryField = (role: string) => {
    if (role === 'agent') {
      return (
        <FieldWithFetch
          as={Input}
          fetchAction={fetchAgentPrefix}
          isFetchedFieldName="isFetchedPrefix"
          label="Agent alias"
          name="prefix"
          placeholder="John Smith"
        />
      )
    }

    return null
  }

  return (
    <>
      <Title level={2} style={{ marginBottom: 10 }}>Management Panel Access</Title>
      <Text style={{ display: 'block', marginBottom: 25 }} type="secondary">
        You can grant access to the management panel to specific wallets and assign roles.
        Admins can do everything. Agents can manage only the whitelist.
      </Text>
      <Formik
        initialValues={{
          address: '',
          prefix: '',
          role: null,
          isFetchedPrefix: false,
          isFetchedUserRole: false,
        }}
        validationSchema={addUserFormScheme}
        onSubmit={(values, { resetForm }) => handleFormSubmit(values, resetForm)}
      >
        {({
          handleSubmit, errors, touched, values, isSubmitting, handleReset, setFieldValue,
        }) => (
          <Form layout="vertical" style={{ width: '100%' }} onFinish={handleSubmit}>
            <Form.Item
              help={(errors.address && touched.address) && errors.address}
              label="Wallet address (Ethereum)"
              validateStatus={(errors.address && touched.address) ? 'error' : 'success'}
            >
              <Field
                allowClear
                as={Input}
                name="address"
                placeholder="0x00…0000"
              />
            </Form.Item>
            <FieldWithFetch
              as={Select}
              fetchAction={fetchUserRole}
              isFetchedFieldName="isFetchedUserRole"
              label="Role"
              name="role"
              placeholder="Role"
              onSelect={(value) => setFieldValue('role', value)}
            >
              <Option disabled={identity?.role === 'agent'} value="agent">Agent</Option>
              <Option disabled={identity?.role === 'agent'} value="admin">Admin</Option>
              <Option style={{ display: 'none' }} value="owner">Owner</Option>
            </FieldWithFetch>
            {values.role && renderSecondaryField(values.role)}
            <Form.Item>
              <Row justify="space-between" style={{ width: '100%', marginTop: 10 }}>
                <Button
                  disabled={
                    values.isFetchedUserRole
                    || (identity?.role === 'agent' && ['agent', 'admin'].includes(values?.role ?? ''))
                  }
                  htmlType="submit"
                  loading={isSubmitting}
                  style={{ width: '48%' }}
                  type="primary"
                >
                  Add
                </Button>
                <Button
                  danger
                  disabled={
                    !values.isFetchedUserRole
                    || (identity?.role === 'agent' && ['agent', 'admin'].includes(values?.role ?? ''))
                    || values.role === 'owner'
                  }
                  loading={isUserRemoving}
                  style={{ width: '48%' }}
                  type="primary"
                  onClick={() => handleRemoveUserClick(values, handleReset)}
                >
                  Remove
                </Button>
              </Row>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </>
  )
}

type AddUserByRole = {
  agent: ({ address, prefix }: UserForm) => Promise<any>
  admin: ({ address }: UserForm) => Promise<any>
}

type RemoveUserByRole = {
  [key: string]: ({ address }: UserForm) => Promise<any>
}

type AddUserByRoleKey = keyof AddUserByRole

type RemoveUserByRoleKey = keyof RemoveUserByRole

export default ManageUsers

