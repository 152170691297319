import {
  Typography, Space, Button, Tag, Tooltip, Modal, Grid, Col,
} from 'antd'
import {
  useAccount, useDisconnect, useNetwork,
} from 'wagmi'
import {
  ReactNode, useContext, useState,
} from 'react'
import { ExportOutlined, SettingOutlined } from '@ant-design/icons'

import { StoreContext } from '../../app/providers/store'
import { truncateAddress } from '../../shared/lib'
import { Settings } from '..'

const { Text } = Typography
const { useBreakpoint } = Grid

const WalletInfo = () => {
  const {
    walletInfo,
    setWalletInfo,
  } = useContext(StoreContext)

  const [isSettingsOpened, setSettingsOpened] = useState(false)

  const { xs } = useBreakpoint()

  const { chain } = useNetwork()
  const { address } = useAccount()
  const { disconnectAsync } = useDisconnect()

  const { identity, token, balance } = walletInfo || {}

  const handleDisconnectClick = async () => {
    localStorage.clear()

    await disconnectAsync()

    setWalletInfo(null)
  }

  const trunkatedAddress = truncateAddress(address as string)

  return (
    <>
      <Space size={xs ? 7 : 12}>
        <Space size={5}>
          {identity && tagByRole[identity.role]}
          {!xs && <>{chain ? tagByChain[chain?.name] : <Tag color="#f5f5f5">Unknown</Tag>}</> }
        </Space>
        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <Text strong style={{ color: 'white', whiteSpace: 'nowrap' }}>
            {trunkatedAddress}
          </Text>
          <Text style={{ color: 'white', whiteSpace: 'nowrap', fontSize: 12 }} type="secondary">
            {balance}
            {' '}
            {token?.symbol}
          </Text>
        </Col>
        <Space size={5}>
          <Tooltip placement="bottom" title="Settings">
            <Button
              icon={<SettingOutlined />}
              style={{ color: 'white' }}
              type="link"
              onClick={() => setSettingsOpened(true)}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Settings">
            <Button
              icon={<ExportOutlined />}
              style={{ color: '#f54245' }}
              type="link"
              onClick={() => handleDisconnectClick()}
            />
          </Tooltip>
        </Space>
      </Space>
      <Modal
        footer={null}
        open={isSettingsOpened}
        title="TIRC settings"
        width={350}
        onCancel={() => setSettingsOpened(false)}
      >
        <Settings />
      </Modal>
    </>
  )
}

const tagByRole: { [key: string]: ReactNode } = {
  owner: <Tag color="#54a757">Owner</Tag>,
  admin: <Tag color="#f59942">Admin</Tag>,
  agent: <Tag color="#189468">Agent</Tag>,
  whitelisted: <Tag color="#4287f5">Whitelisted</Tag>,
}

const tagByChain: { [key: string]: ReactNode } = {
  Goerli: <Tag color="#3bbce3">Goerli</Tag>,
  Ethereum: <Tag color="#503eb3">Ethereum</Tag>,
}

export default WalletInfo
