/* eslint-disable react-hooks/exhaustive-deps */
import { message, Spin } from 'antd'
import { useContext, useEffect } from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useAccount, useDisconnect } from 'wagmi'

import { useWalletInfo } from '../../shared/hooks'
import { Role } from '../../shared/types'
import { StoreContext } from '../providers/store'

import { routesByRoles, initialRouteByRoles } from './routes'

const Guard = () => {
  const { walletInfo } = useContext(StoreContext)

  const location = useLocation()

  const { isConnected } = useAccount()
  const { disconnect } = useDisconnect()

  const { getWalletInfo } = useWalletInfo()

  const { identity } = walletInfo || {}

  const onConnectWalletPage = location.pathname === '/connect-wallet'
  const isAuthenticated = !!identity
  const isUser = isAuthenticated && identity.role === 'user'
  const isOtherRoleRoute = isAuthenticated && !isUser && !routesByRoles[identity.role].includes(location.pathname)
  const initialRoleRoute = isAuthenticated ? initialRouteByRoles[identity.role as Role] : '/'

  useEffect(() => {
    if (isConnected) { getWalletInfo() }
  }, [isConnected, getWalletInfo])

  useEffect(() => {
    if (isConnected && isUser) {
      disconnect()

      message.error('This user doesn\'t have access')
    }
  }, [isConnected, isUser])

  if (isConnected) {
    if (isAuthenticated) {
      if (isUser) {
        return <Navigate replace to="/connect-wallet" />
      }

      if (onConnectWalletPage) {
        return <Navigate replace to={initialRoleRoute} />
      }

      if (isOtherRoleRoute) {
        return <Navigate replace to={initialRoleRoute} />
      }

      return <Outlet />
    }

    return (
      <Spin spinning>
        <Outlet />
      </Spin>
    )
  }

  if (!onConnectWalletPage && !isConnected) {
    return <Navigate replace to="/connect-wallet" />
  }

  if (onConnectWalletPage && !isConnected) {
    return <Outlet />
  }

  return <></>
}

export default Guard
