/* eslint-disable react-hooks/exhaustive-deps */
import { ethers } from 'ethers'
import { useCallback, useContext } from 'react'
import { useAccount } from 'wagmi'

import { StoreContext } from '../../../app/providers/store'
import {
  useTokenContract, useToken, useIdentity,
} from '..'

const useWalletInfo = () => {
  const { setWalletInfo } = useContext(StoreContext)

  const { address } = useAccount()

  const { tokenContract } = useTokenContract()

  const { getToken } = useToken()
  const { getIdentity } = useIdentity()

  const getWalletInfo = useCallback(async () => {
    const token = await getToken()
    const identity = await getIdentity()

    const balance = await tokenContract?.balanceOf(address)
    const whitelistId: string = await tokenContract?.getWhitelist(address)

    const convertedBalance = ethers.utils.formatUnits(balance, token.decimals)

    setWalletInfo({
      balance: convertedBalance, whitelistId, identity, token,
    })
  }, [tokenContract])

  return { getWalletInfo }
}

export default useWalletInfo
