import { Layout } from 'antd'
import styled from 'styled-components'

const { Header } = Layout

export const StyledHeader = styled(Header)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    border-radius: 13px; 

    @media only screen and (max-width: 768px) {
        padding: 15px !important;
    }

`
