import { WagmiConfig } from 'wagmi'

import { getWagmiClient } from '../../../shared/configs/wagmiConfig'

import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const wagmiClient = getWagmiClient()

const WagmiProvider = ({ children }: Props) => (
  <WagmiConfig client={wagmiClient}>
    {children}
  </WagmiConfig>
)

export default WagmiProvider
