import { Layout } from 'antd'
import styled from 'styled-components'

const { Content } = Layout

export const StyledLayout = styled(Layout)`
    border-radius: 12px;
    background-color: #fafafa;
`

export const StyledContent = styled(Content)`
    padding: 30px 0;

    @media only screen and (max-width: 768px) {
        padding: 20px 0;
    }
`
