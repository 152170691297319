import { message, Typography } from 'antd'
import { useNetwork } from 'wagmi'

const { Link } = Typography

const useBlockExplorer = () => {
  const { chain } = useNetwork()

  const showExplorerMessage = (txHash: string) => {
    if (chain) {
      message.info(
        <>
          You can
          {' '}
          <Link href={blockExplorerTxLinkByChain(chain?.network, txHash)} target="_blank">track transaction</Link>
          {' '}
          in block explorer
        </>,
        5,
      )
    }
  }

  const getExplorerLinkByAddress = (address: string) => {
    if (chain) {
      return blockExplorerAddressLinkByChain(chain?.network, address)
    }
  }

  return {
    showExplorerMessage, getExplorerLinkByAddress,
  }
}

const blockExplorerTxLinkByChain = (chainName: string, txHash: string) => {
  if (chainName === 'goerli') {
    return `https://goerli.etherscan.io/tx/${txHash}`
  }

  return `https://etherscan.io/tx/${txHash}`
}

const blockExplorerAddressLinkByChain = (chainName: string, address: string) => {
  if (chainName === 'goerli') {
    return `https://goerli.etherscan.io/address/${address}`
  }

  return `https://etherscan.io/address/${address}`
}

export default useBlockExplorer
