/* eslint-disable no-underscore-dangle */
import { waitForTransaction } from '@wagmi/core'
import {
  Typography, Space, message, Switch, Row,
} from 'antd'
import {
  useCallback, useContext, useState,
} from 'react'
import { useNetwork } from 'wagmi'

import { StoreContext } from '../../app/providers/store'
import { useBlockExplorer, useTokenContract } from '../../shared/hooks'
import { handleError, truncateAddress } from '../../shared/lib'

const { Text, Link } = Typography

const Settings = () => {
  const {
    setTokenContractPaused, isTokenContractPaused, walletInfo, currentTokenPair,
  } = useContext(StoreContext)

  const [isSwitching, setSwitching] = useState(false)

  const { chain } = useNetwork()

  const { switchPause } = useTokenContract()
  const { showExplorerMessage, getExplorerLinkByAddress } = useBlockExplorer()

  const { identity } = walletInfo || {}

  const handleSwitchChange = useCallback(async () => {
    setSwitching(true)

    try {
      const { hash } = await switchPause(isTokenContractPaused ? 'unpause' : 'pause')

      showExplorerMessage(hash)

      await waitForTransaction({ chainId: chain?.id, hash })

      setTokenContractPaused(!isTokenContractPaused)

      message.success(`Token was switched to ${isTokenContractPaused ? 'unpaused' : 'paused'}`)
    } catch (error) {
      handleError(error)
    }

    setSwitching(false)
  }, [chain?.id, isTokenContractPaused, setTokenContractPaused, showExplorerMessage, switchPause])

  return (
    <Space direction="vertical" size={15} style={{ width: 300, marginTop: 20 }}>
      <Row align="middle" justify="space-between">
        <Text>Token Name</Text>
        <Text strong>Tinaga Island Resort (TIRC)</Text>
      </Row>
      <Row align="middle" justify="space-between">
        <Text>Token Address</Text>
        <Link
          copyable={{ text: currentTokenPair.contract }}
          href={getExplorerLinkByAddress(currentTokenPair.contract)}
          strong
        >
          {truncateAddress(currentTokenPair.contract)}
        </Link>
      </Row>
      {identity?.role && ['admin', 'owner'].includes(identity?.role) && (
        <Row align="middle" justify="space-between">
          <Text>Token Active</Text>
          <Switch
            checked={!isTokenContractPaused}
            loading={isSwitching}
            style={{ width: 40 }}
            onChange={handleSwitchChange}
          />
        </Row>
      )}
    </Space>
  )
}

export default Settings
