import * as Yup from 'yup'

export const addUserFormScheme = Yup.object()
  .shape({
    address: Yup.string()
      .required('Wallet address is required')
      .matches(/^0x[a-fA-F0-9]{40}$/, 'Invalid wallet address'),
    role: Yup.string()
      .nullable()
      .required('Role is required'),
    prefix: Yup.string()
      .nullable()
      .when('role', {
        is: 'agent',
        then: Yup.string()
          .nullable()
          .required('Agent alias is required'),
      }),
    isFetchedRole: Yup.boolean(),
    isFetchedPrefix: Yup.boolean()
      .when('role', {
        is: 'agent',
        then: Yup.boolean(),
      }),
  })

export const addUserToWhitelistFormScheme = Yup.object()
  .shape({
    address: Yup.string()
      .required('Wallet address is required')
      .matches(/^0x[a-fA-F0-9]{40}$/, 'Invalid wallet address'),
    whitelistId: Yup.string()
      .nullable()
      .required('Wallet alias is required'),
    isFetchedWhitelistId: Yup.boolean(),
  })

export const mintTokensScheme = Yup.object()
  .shape({
    address: Yup.string()
      .required('Wallet address is required')
      .matches(/^0x[a-fA-F0-9]{40}$/, 'Invalid wallet address'),
    whitelistId: Yup.string()
      .nullable()
      .required('Wallet alias is required'),
    amount: Yup.number()
      .min(0.001, 'Amount can\'t be 0')
      .required('Amount is required')
      .typeError('Amount is required'),
    releaseDate: Yup.string()
      .when('withReleaseDate', {
        is: true,
        then: Yup.string()
          .required('Release date is required'),
      }),
    withReleaseDate: Yup.boolean(),
    isFetchedWhitelistId: Yup.boolean(),
  })

