export const routesByRoles: RoutesByRoles = {
  admin: ['/'],
  owner: ['/'],
  whitelisted: ['/whitelist-info'],
  agent: ['/'],
}

export const initialRouteByRoles = {
  admin: '/',
  owner: '/',
  whitelisted: '/whitelist-info',
  agent: '/',
}

type RoutesByRoles = {
    [key: string]: string[]
}

