import {
  createContext, useState, useMemo, useEffect,
} from 'react'
import { useNetwork } from 'wagmi'

import { TokenPair, WalletInfo } from '../../../shared/types'
import { mainnetTokenPair, tokenPairsById } from '../../../shared/configs/tokenPairs'

import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

interface StoreContextProps {
  walletInfo: WalletInfo | null
  currentTokenPair: TokenPair
  isWalletInfoLoading: boolean
  isTokenContractPaused: boolean | undefined
  setWalletInfo: (walletInfo: WalletInfo | null) => void
  setCurrentTokenPair: (tokenPair: TokenPair) => void
  setWalletInfoLoading: (state: boolean) => void
  setTokenContractPaused: (state: boolean) => void
}

const StoreProvider = ({ children }: Props) => {
  const { chain } = useNetwork()

  const [currentTokenPair, setCurrentTokenPair] = useState<TokenPair>(mainnetTokenPair)

  const [walletInfo, setWalletInfo] = useState<WalletInfo | null>(null)
  const [isWalletInfoLoading, setWalletInfoLoading] = useState(false)

  const [isTokenContractPaused, setTokenContractPaused] = useState<boolean | undefined>(undefined)

  const memoizedContext = useMemo(() => ({
    walletInfo,
    currentTokenPair,
    isWalletInfoLoading,
    isTokenContractPaused,
    setWalletInfo,
    setCurrentTokenPair,
    setWalletInfoLoading,
    setTokenContractPaused,
  }), [
    walletInfo,
    currentTokenPair,
    isWalletInfoLoading,
    isTokenContractPaused,
    setWalletInfo,
    setCurrentTokenPair,
    setWalletInfoLoading,
    setTokenContractPaused,
  ])

  useEffect(() => {
    if (chain?.id) {
      setCurrentTokenPair(tokenPairsById[chain.id])
    }
  }, [chain?.id])

  return (
    <StoreContext.Provider value={memoizedContext}>
      {children}
    </StoreContext.Provider>
  )
}

export const StoreContext = createContext<StoreContextProps>({} as StoreContextProps)

export default StoreProvider

