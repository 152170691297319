import { ethers } from 'ethers'
import { useContext } from 'react'
import { useAccount } from 'wagmi'

import { useProxyContract, useTokenContract } from '..'
import { StoreContext } from '../../../app/providers/store'
import { Role } from '../../types'

const useIdentity = () => {
  const { walletInfo } = useContext(StoreContext)

  const { address } = useAccount()

  const { tokenContract } = useTokenContract()
  const { proxyContract } = useProxyContract()

  const getAgentPrefix = async (agentAddress: string) => {
    const prefix = await proxyContract?.getAgentPrefix(agentAddress)

    return prefix
  }

  const getWhitelistId = async (whitelistAddress: string) => {
    const whitelistId = await tokenContract?.getWhitelist(whitelistAddress)

    const convertedWhitelistId = ethers.utils.parseBytes32String(whitelistId)

    return convertedWhitelistId
  }

  const checkAgent = async (agentAddress: string) => {
    const isAgent = await proxyContract?.isWhitelistAgent(agentAddress)

    return isAgent
  }

  const checkOwner = async (ownerAddress: string) => {
    const isOwner = await tokenContract?.isOwner(ownerAddress)

    return isOwner
  }

  const checkAdmin = async (adminAddress: string) => {
    const isAdmin = await tokenContract?.isAdmin(adminAddress)

    return isAdmin
  }

  const addAdmin = async (adminAddress: string) => await tokenContract?.addAdmin(adminAddress)

  const removeAdmin = async (adminAddress: string) => await tokenContract?.removeAdmin(adminAddress)

  const addAgent = async (
    agentAddress: string,
    prefix?: string,
  ) => await proxyContract?.addWhitelistAgent(agentAddress, prefix)

  const removeAgent = async (agentAddress: string) => await proxyContract?.removeWhitelistAgent(agentAddress)

  const addToWhitelist = async (whitelistAddress: string, whitelistId?: string) => {
    const { identity } = walletInfo || {}

    if (whitelistId) {
      const preliminaryId = identity?.prefix ? `-${whitelistId}` : whitelistId
      const convertedId = ethers.utils.formatBytes32String(preliminaryId)

      if (identity?.role !== 'agent') {
        return await tokenContract?.addToWhitelist(whitelistAddress, convertedId)
      }

      return await proxyContract?.addWhitelistInvestor(whitelistAddress, convertedId)
    }
  }

  const removeFromWhitelist = async (whitelistAddress: string) => {
    const { identity } = walletInfo || {}

    if (identity?.role !== 'agent') {
      return await tokenContract?.removeFromWhitelist(whitelistAddress)
    }

    return await proxyContract?.removeWhitelistInvestor(whitelistAddress)
  }

  const getIdentity = async () => {
    const isOwner = await tokenContract?.isOwner(address)

    if (isOwner) {
      return generateIdentityByRole('owner')
    }

    const isAdmin = await tokenContract?.isAdmin(address)

    if (isAdmin) {
      return generateIdentityByRole('admin')
    }

    const isAgent = await proxyContract?.isWhitelistAgent(address)

    if (isAgent) {
      const prefix = await getAgentPrefix(address as string)

      return generateIdentityByRole('agent', prefix)
    }

    const whitelistId = await getWhitelistId(address as string)

    if (whitelistId && parseInt(whitelistId, 10) !== 0) {
      return generateIdentityByRole('whitelisted', whitelistId)
    }

    return ({ role: 'user' })
  }

  const generateIdentityByRole = (role: Role, option?: string) => {
    if (role === 'agent' && option) {
      return ({
        role,
        prefix: option,
      })
    }

    if (role === 'whitelisted' && option) {
      return ({
        role,
        whitelistId: option,
      })
    }

    return ({ role })
  }

  return {
    getIdentity,
    getWhitelistId,
    getAgentPrefix,
    addToWhitelist,
    removeFromWhitelist,
    addAgent,
    removeAgent,
    addAdmin,
    removeAdmin,
    checkAgent,
    checkAdmin,
    checkOwner,
  }
}

export default useIdentity
