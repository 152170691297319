import { message } from 'antd'

import { OptionKeys } from '../types'

export const truncateAddress = (address: string) => {
  if (!address) { return '-' }
  const match = address.match(
    /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/,
  )
  if (!match) { return address }

  return `${match[1]}…${match[2]}`
}

export const convertToOptions = <T>(option: OptionKeys<T>, array: Array<T>) => {
  const { valueKey, labelKey } = option

  return array.map(o => {
    const value = o[valueKey]
    const label = o[labelKey]

    return ({ value, label })
  })
}

export const handleError = (err: unknown) => {
  const error = err as Error

  if (error.message.match('ACTION_REJECTED')) {
    return message.warning('User rejected request')
  }

  if (error.message.match('Sorry, contract is paused')) {
    return message.error('Sorry, contract is paused')
  }

  return message.error('Error, please try again later')
}
