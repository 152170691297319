import {
  Row, Space, Typography, Avatar, message,
} from 'antd'
import {
  useContext, useState,
} from 'react'
import { useConnect } from 'wagmi'

import tinagaLogo from '../../assets/tinagaLogo.png'
import { StoreContext } from '../../app/providers/store'
import { useWalletInfo } from '../../shared/hooks'
import { CoinbaseIcon, MetaMaskIcon, WalletConnectIcon } from '../../shared/icons'

import { StyledButton } from './styles'

import type { ReactNode } from 'react'
import type { Connector } from 'wagmi'

const { Title } = Typography

const ConnectWallet = () => {
  const { setWalletInfo } = useContext(StoreContext)

  const [connectingWallet, setConnectingWallet] = useState<string | null>(null)

  const { connectors, connect } = useConnect()

  const { getWalletInfo } = useWalletInfo()

  const handleConnectorClick = async (connector: Connector) => {
    if (connector.ready) {
      try {
        setWalletInfo(null)

        setConnectingWallet(connector.id)

        await connect({ connector })
        await getWalletInfo()

        setConnectingWallet(null)
      } catch (error) {
        setConnectingWallet(null)
      }
    }

    if (!connector.ready && connector.name === 'MetaMask') {
      return message.warning('MetaMask wallet extension not installed')
    }
  }

  return (
    <Row justify="center" style={{ width: '100%' }}>
      <Space align="center" direction="vertical" size={15} style={{ marginTop: 100 }}>
        <Avatar size={90} src={tinagaLogo} />
        <Title style={{ marginTop: 0 }}>TIRC Management Panel</Title>
        <Space direction="vertical" size={12} style={{ width: 250 }}>
          {connectors.map((c) => (
            <StyledButton
              key={c.name}
              block
              icon={iconsByConnectorId[c.id]}
              loading={connectingWallet === c.id}
              size="large"
              type="default"
              onClick={() => handleConnectorClick(c)}
            >
              {c.name}
            </StyledButton>
          ))}
        </Space>
      </Space>

    </Row>
  )
}

const iconsByConnectorId: ConnectorIcons = {
  coinbaseWallet: <CoinbaseIcon />,
  metaMask: <MetaMaskIcon />,
  walletConnect: <WalletConnectIcon />,
}

type ConnectorIcons = {
  [key: string]: ReactNode
}

export default ConnectWallet
