
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'

import ThemeProvider from './theme'
import WagmiProvider from './wagmi'
import StoreProvider from './store'

import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const Providers = ({ children }: Props) => (
  <ConfigProvider theme={{
    token: { colorPrimary: '#54a757', colorText: '#242323' },
    components: { Tooltip: { colorBgDefault: '#383838' } },
  }}
  >
    <ThemeProvider>
      <WagmiProvider>
        <StoreProvider>
          <BrowserRouter>
            {children}
          </BrowserRouter>
        </StoreProvider>
      </WagmiProvider>
    </ThemeProvider>
  </ConfigProvider>

)

export default Providers
