import './index.css'
import { Route, Routes } from 'react-router-dom'

import 'antd/dist/reset.css'

import {
  ConnectWallet,
  WhitelistInfo,
  Main,
} from '../pages'

import Layout from './layout'
import Providers from './providers'
import Guard from './guard'

const App = () => (
  <Providers>
    <Routes>
      <Route element={<Guard />}>
        <Route element={<ConnectWallet />} path="/connect-wallet" />
        <Route element={<Layout />}>
          <Route element={<Main />} path="/" />
          <Route element={<WhitelistInfo />} path="/whitelist-info" />
        </Route>
      </Route>
    </Routes>
  </Providers>
)

export default App
